import { MediaInput } from './media-input/media-input';
import React from 'react';
import classNames from 'classnames';
import { ReviewFormType } from './review-form-type';
import { classes, st } from './review-form.st.css';
import {
  REVIEW_BOX,
  LIST_ITEM,
  NAME_FIELD,
  EMAIL_FIELD,
  RATING_FIELD,
  TITLE_FIELD,
  BODY_FIELD,
  FORM_ERROR,
  MEDIA_FIELD,
  PENDING,
} from '../../../common/constants/data-hooks';
import ListItemContainer from '../list-item-container/list-item-container';
import { useNavigationPrompt } from '../../hooks/use-navigation-prompt';
import { useCancelFlow, useCancelFlowExecutor } from '../../hooks/use-cancel-flow';
import { TextField } from './text-field/text-field';
import { useReviewFormState } from './review-form-state';
import { TextAreaExtended } from '../text-area-extended/text-area-extended';
import { isOfType, keys } from '~/ts-utils';
import { ReviewContent } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { FormErrorNotification } from './form-error-notification/form-error-notification';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { TranslationKey } from '~reviews/locale-types';
import { SubmitCancel } from '../_common/submit-cancel/submit-cancel';
import { useApi } from '../api-provider/use-api';
import { FormErrorNotificationClickable } from './form-error-notification/form-error-notification-clickable';
import { useHighlightForm } from './use-highlight-form';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getInstance } from '~reviews/common/store/base-params/base-params-selectors';
import { wrapWithDevConnectionState } from '~reviews/common/wrap-with-dev-connection-state';
import { uploadMediaFile as uploadMediaFileOriginal } from './upload-media';
import { RatingsMode as Mode, Ratings, RatingsSize as Size } from 'wix-ui-tpa/cssVars';
import { useFormSettings } from '~reviews/common/store/configuration/use-form-settings';
import { makeReviewSelector } from '~reviews/common/services/id-utils';
import { usePrevious } from '~reviews/Widget/hooks/use-previous';

const ReviewForm: React.FC<ReviewFormType> = ({
  onSubmit,
  onCancel,
  onValidationFailed,
  className,
  initialContent,
  isEdit,
  isInList,
  isPending,
  id,
  dataHook,
  requireContact,
  error,
  forceHoverState,
  forceErrorState,
}) => {
  const { isMobile, isEditor } = useEnvironment();
  const { requestLogin, focusFormBody } = useApi((state, actions) => ({
    requestLogin: actions.requestLogin,
    focusFormBody: actions.biFocusFormBody,
  }));
  const { title, body, media, ratingLabel } = useFormSettings();
  const formState = useReviewFormState({
    initialContent,
    isContactRequired: requireContact,
    isTitleRequired: title.enabled && title.required,
    isBodyRequired: body.enabled && body.required,
    isMediaRequired: media.enabled && media.required,
    submissionError: error,
  });
  const fields = formState.fields;
  const prevForceErrorState = usePrevious(forceErrorState);
  React.useEffect(() => {
    if (forceErrorState) {
      formState.forceErrorState();
    } else if (prevForceErrorState) {
      formState.reset();
    }
  }, [forceErrorState]);

  const t = useTranslate();

  // optional translate function;
  const opT = (s: TranslationKey | undefined, args?: any) =>
    s === undefined ? undefined : t(s, args);

  const { instance, mediaUploadState } = useApi((state, _a, _h, ctx) => ({
    instance: getInstance(state),
    mediaUploadState: ctx.devToolsState.mediaUploadState,
  }));

  const uploadMediaFileFn = React.useMemo(
    () => wrapWithDevConnectionState(uploadMediaFileOriginal({ instance }), () => mediaUploadState),
    [mediaUploadState, instance],
  );

  const handleSubmit = async () => {
    if (formState.isValid()) {
      onSubmit({
        content: formState.getContent(),
        updatedFields: formState
          .getUpdatedFields()
          .filter((key): key is keyof ReviewContent => keys(initialContent).includes(key as any)),
        reset: formState.reset,
        contact: formState.getContact(),
      });
    } else {
      const invalidFields = formState.validate();
      onValidationFailed?.(invalidFields);
    }
  };

  const resolveCancelFlow = useCancelFlow({
    onCancel: () =>
      onCancel(formState.getContent(), formState.reset, formState.getUpdatedFields().length === 0),
    isReply: false,
    selector: makeReviewSelector(id),
  });

  useNavigationPrompt({ resolveCancelFlow, formIsMounted: true });

  const handleCancel = useCancelFlowExecutor(resolveCancelFlow);

  const mediaCount = fields.media.value.filter(isOfType(['READY', 'PENDING'])).length;

  const formatAriaLabel = ({
    translated,
    isRequired,
  }: {
    translated: string;
    isRequired: boolean;
  }) => {
    return isRequired ? `${translated}. ${t('field-label.required')}` : translated;
  };

  const highlightIds = useHighlightForm(formState);
  const content = (
    <div className={st(classes.contentContainer, { isPending })}>
      {formState.submissionError && !isPending && (
        <div
          className={classes.formError}
          id={highlightIds.notification}
          data-hook={FORM_ERROR}
          tabIndex={-1}
        >
          {formState.submissionError.type === 'EMAIL_EXISTS_ERROR' ? (
            <FormErrorNotificationClickable
              className={st(classes.formErrorNotification, classes.spacingNormal)}
              text={t(formState.submissionError.key)}
              ctaText={t(formState.submissionError.ctaKey)}
              onClick={() => requestLogin()}
            />
          ) : (
            <FormErrorNotification
              className={st(classes.formErrorNotification, classes.spacingNormal)}
              text={
                formState.submissionError.type === 'TRANSLATION'
                  ? t(formState.submissionError.key, formState.submissionError.keyValue)
                  : formState.submissionError.message
              }
              onClose={formState.clearSubmissionError}
            />
          )}
        </div>
      )}
      {requireContact && (
        <div className={st(classes.contactSection, classes.spacingNormal)}>
          <TextField
            id={highlightIds.name}
            label={t('field-label.name', { required: true })}
            aria-label={formatAriaLabel({
              translated: t('field-label.name', { required: false }),
              isRequired: true,
            })}
            className={classes.contactName}
            value={fields.name.value}
            onChange={(e) => formState.onFieldChange('name', e.currentTarget.value)}
            errorMessage={opT(fields.name.error)}
            error={!!fields.name.error}
            autoComplete="name"
            autoFocus={!isEditor}
            withClearButton
            onClear={() => formState.onFieldChange('name', '')}
            data-hook={NAME_FIELD}
            disabled={isPending}
          />
          <TextField
            id={highlightIds.email}
            label={t('field-label.email', { required: true })}
            aria-label={formatAriaLabel({
              translated: t('field-label.email', { required: false }),
              isRequired: true,
            })}
            className={classes.contactEmail}
            value={fields.email.value}
            onChange={(e) => formState.onFieldChange('email', e.currentTarget.value)}
            errorMessage={opT(fields.email.error)}
            error={!!fields.email.error}
            autoComplete="email"
            withClearButton
            onClear={() => formState.onFieldChange('email', '')}
            data-hook={EMAIL_FIELD}
            disabled={isPending}
          />
        </div>
      )}
      <div
        className={st(classes.ratingFieldWrapper, classes.spacingNormal)}
        id={highlightIds.rating}
      >
        <Ratings
          starsAriaLabels={['1', '2', '3', '4', '5']}
          className={st(classes.rating, { forcedHover: forceHoverState })}
          label={formatLabel({ translated: ratingLabel, isRequired: true })}
          aria-label={formatAriaLabel({
            translated: ratingLabel,
            isRequired: true,
          })}
          value={fields.rating.value}
          onSelect={(rating) => {
            formState.onFieldChange('rating', rating);
          }}
          mode={Mode.Input}
          size={Size.Large}
          errorMessage={opT(fields.rating.error)}
          error={!!fields.rating.error}
          data-hook={RATING_FIELD}
          disabled={isPending}
        />
      </div>
      {title.enabled ? (
        <TextField
          id={highlightIds.title}
          label={formatLabel({ translated: title.label, isRequired: title.required })}
          aria-label={formatAriaLabel({
            translated: title.label,
            isRequired: title.required,
          })}
          value={fields.title.value}
          onChange={(e) => formState.onFieldChange('title', e.currentTarget.value)}
          className={st(
            st(classes.reviewTitle, { forcedHover: forceHoverState }),
            fields.title.error ? classes.spacingNormal : classes.spacingNoBottomMargin,
          )}
          maxLength={title.limit}
          showCharCount
          errorMessage={opT(fields.title.error)}
          error={!!fields.title.error}
          withClearButton
          onClear={() => formState.onFieldChange('title', '')}
          data-hook={TITLE_FIELD}
          disabled={isPending}
        />
      ) : null}
      {body.enabled ? (
        <TextAreaExtended
          id={highlightIds.body}
          label={formatLabel({ translated: body.label, isRequired: body.required })}
          ariaLabel={formatAriaLabel({
            translated: body.label,
            isRequired: body.required,
          })}
          value={fields.body.value}
          onChange={(v) => formState.onFieldChange('body', v)}
          className={st(
            st(classes.reviewBody, { forcedHover: forceHoverState }),
            fields.body.error ? classes.spacingNormal : classes.spacingNoBottomMargin,
          )}
          maxLength={body.limit ?? 1000}
          showCharCount
          errorMessage={opT(fields.body.error)}
          error={!!fields.body.error}
          dataHook={BODY_FIELD}
          disabled={isPending}
          onFocus={() => focusFormBody()}
        />
      ) : null}
      {media.enabled
        ? (() => {
            const getLabel = (required: boolean) =>
              t(
                {
                  image: 'field-label.add-images' as const,
                  video: 'field-label.add-videos' as const,
                  all: 'field-label.add-images-videos' as const,
                }[media.allowedMedia],
                {
                  count: mediaCount,
                  limit: media.limit,
                  required,
                },
              );
            const label = getLabel(false);
            return (
              <MediaInput
                uploadMediaFileFn={uploadMediaFileFn}
                id={highlightIds.media}
                onChange={(m) => formState.onFieldChange('media', m)}
                media={fields.media.value}
                label={getLabel(media.required)}
                aria-label={formatAriaLabel({
                  translated: label,
                  isRequired: media.required,
                })}
                className={st(classes.mediaInput, classes.spacingNormal)}
                disabled={mediaCount >= media.limit || isPending}
                maxLength={media.limit}
                errorMessage={opT(fields.media.error)}
                error={!!opT(fields.media.error)}
                allowedMedia={media.allowedMedia}
                dataHook={MEDIA_FIELD}
              />
            );
          })()
        : null}
      <div className={classes.footer}>
        <SubmitCancel
          className={classes.buttons}
          onCancel={handleCancel}
          isPending={isPending}
          isEdit={isEdit}
          buttonSize="medium"
        />
      </div>
    </div>
  );

  return isInList ? (
    <ListItemContainer
      id={id}
      isPending={false}
      isReply={false}
      dataHook={classNames(LIST_ITEM, REVIEW_BOX, dataHook, isPending && PENDING)}
    >
      <form
        aria-label={t('review-form.aria-label-edit')}
        className={st(classes.container, { isMobile }, className, classes.isExtended)}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {content}
      </form>
    </ListItemContainer>
  ) : (
    <form
      id={id}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      aria-label={t('review-form.aria-label-create')}
      data-hook={classNames(REVIEW_BOX, dataHook, isPending && PENDING)}
      className={st(classes.container, { isMobile }, className, classes.isExtended)}
    >
      {content}
    </form>
  );
};

const formatLabel = ({ translated, isRequired }: { translated: string; isRequired: boolean }) => {
  return isRequired ? `${translated}*` : translated;
};

export default ReviewForm;
